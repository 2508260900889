import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>Cryptocurrency Development</span> Company</h1>
                <p className='pharagraph mb-0'>Coinsclone as a reputed cryptocurrency development company offers you world-class cryptocurrency development services. Our developers tailor the cryptocurrencies based on our client’s business specifications and needs. As a well-known cryptocurrency development company, we provide numerous cryptocurrency development solutions that suit your business and your budget.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Talk to our Experts</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/crypto-dev/cryptocurrency-development-company.webp"
                alt="Cryptocurrency Development Company"
                className='d-none d-md-block float-end'
                width={396}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection