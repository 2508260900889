import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="main-heading text-center">Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                Are there any legal considerations in developing a cryptocurrency?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">YES. There are certain legal considerations in developing a cryptocurrency with relevant features. Some of the laws include AML/KYC regulations, tax obligations,  intellectual property rights, etc.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                How can I find a development team for my cryptocurrency project?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">You can find the best development team by analyzing the crypto market, their years of experience in cryptocurrency projects, developer & technician team, and past portfolio. By deeply researching numerous development teams you can find the best one among them.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                How much does it cost to develop a cryptocurrency?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The average cost to develop a cryptocurrency ranges between $5,000 to $9,000. The cost may vary based on the customizations, add-on feature implementation, security feature integration, etc.
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                Can a cryptocurrency development company assist with ICOs (Initial Coin Offerings)?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">YES. Some of the popular cryptocurrency development companies assist you in creating an ICO website to raise funds for your crypto projects. You can purchase an ICO script to develop your ICO project in a hassle-free manner.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>
  )
}

export default FaqSection